import { UserActivity } from '../../services/userActivity';
import { GoogleTagManager } from '../../gateways/gtm';
import { USER_ACTIVITY } from '../../constants/tracking';

/**
 * User played an on-demand video
 *
 * @typedef  {Object} userPlayedVideoEvent
 * @property {string} category
 * @property {string} sessionTitle
 * @property {string} role
 * @property {string} level
 * @property {array}  products
 * @property {array}  speakers
 */

/**
 * User clicked an "add to calendar" list item
 *
 * @typedef  {Object} userClickedAddToCalendarEvent
 * @property {string} sessionTitle
 * @property {string} role
 * @property {string} level
 * @property {array} products
 * @property {array} speakers
 */

/**
 * User clicked login cta
 *
 * @typedef  {Object} userClickedLoginCtaEvent
 * @property {string} category
 * @property {string} sessionTitle
 * @property {string} role
 * @property {string} level
 * @property {array}  products
 * @property {array}  speakers
 */

/**
 * User clicked signup cta
 *
 * @typedef  {Object} userClickedSignupCtaEvent
 * @property {string} category
 * @property {string} sessionTitle
 * @property {string} role
 * @property {string} level
 * @property {array}  products
 * @property {array}  speakers
 */

const reducers = {
  /**
   * @param {Object} state
   * @param {Object} state.playedVideos - Represents which on-demand videos a user played
   * @param {Object}                action
   * @param {UserPlayedVideoEvent}  action.payload
   */
  USER_PLAYED_VIDEO: (state, action) => {
    if (state.playedVideo) return state;

    const { payload } = action;
    UserActivity.dispatch(USER_ACTIVITY.PLAYED_VIDEO, payload.asUserActivity());
    GoogleTagManager.dispatch(payload.asGTM());

    return {
      ...state,
      playedVideo: true
    };
  },

  /**
   * @param {Object} state
   * @param {Object} action
   * @param {userClickedAddToCalendarEvent} action.payload
   */
  USER_CLICKED_ADD_TO_CALENDAR: (state, action) => {
    const { payload } = action;
    UserActivity.dispatch(
      USER_ACTIVITY.CLICKED_ADD_TO_CALENDAR,
      payload.asUserActivity()
    );
    GoogleTagManager.dispatch(payload.asGTM());

    return {
      ...state
    };
  },

  /**
   * @param {Object} state
   * @param {Object}                    action
   * @param {UserClickedLoginCtaEvent}  action.payload
   */
  USER_CLICKED_LOGIN_CTA: (state, action) => {
    const { payload } = action;
    UserActivity.dispatch(
      USER_ACTIVITY.CLICKED_LOGIN_CTA,
      payload.asUserActivity()
    );

    return {
      ...state
    };
  },

  /**
   * @param {Object} state
   * @param {Object}                     action
   * @param {UserClickedSignupCtaEvent}  action.payload
   */
  USER_CLICKED_SIGNUP_CTA: (state, action) => {
    const { payload } = action;
    UserActivity.dispatch(
      USER_ACTIVITY.CLICKED_SIGNUP_CTA,
      payload.asUserActivity()
    );

    return {
      ...state
    };
  }
};

const userActivityReducer = (state, action) => {
  return reducers[action.type](state, action);
};

export default userActivityReducer;
