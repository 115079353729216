import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AuthContext from '.';
import config from '../../config/index';
import { THContainer } from '../../gateways/trailhead';
import errorNotifier from '../../utils/errorNotifier';

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState();
  useEffect(() => {
    if (!config.auth.isEnabled) {
      setIsLoggedIn(true);
    } else {
      THContainer()
        .checkLogin()
        .then(isAuthenticated => setIsLoggedIn(isAuthenticated))
        .catch(e => errorNotifier(e, { THContainer: window.THContainer }));
    }
  }, [isLoggedIn]);
  return (
    <AuthContext.Provider value={{ isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
