import isAuthenticated from '../utils/isAuthenticated';
import errorNotifier from '../utils/errorNotifier';

/**
 * `defaultFns` for when user is not in Native experience. Defaults can be
 * passed in as an argument to `THContainer` (good for testing) or otherwise will be set as per below
 */
const defaultFns = {
  dispatchEvent: event => {
    document.dispatchEvent(event);
  },
  checkLogin: async onCheckLogin => {
    const isLoggedIn = await isAuthenticated();
    if (onCheckLogin) {
      onCheckLogin(isLoggedIn);
    }
    return isLoggedIn;
  }
};

export const THContainerError = method =>
  new Error(`Cannot find method '${method}' on THContainer`);
/**
 * Implements "short-term" solution to support User Activity Tracking on both
 * web and mobile. More information here:
 * https://salesforce.quip.com/i8VKAUGi9ZX8#KaFACASHKPF
 */
const THContainerWrapper = (defaults = defaultFns, window = global) => {
  /**
   * Checks for the presence of the THContainer on the window, which is injected by ThGO on Native. If Native THContainer exists, use its methods, else use defaults.
   */
  const containerFns = window?.THContainer || defaults;
  return {
    dispatchEvent: event => {
      if (!containerFns.dispatchEvent) {
        const e = THContainerError(`dispatchEvent`);
        errorNotifier(e, { THContainer: window.THContainer });
        throw THContainerError(`dispatchEvent`);
      }
      containerFns.dispatchEvent(event);
    },
    checkLogin: async onCheckLogin => {
      if (!containerFns.checkLogin) {
        throw THContainerError(`checkLogin`);
      }
      const isLoggedIn = await containerFns.checkLogin(onCheckLogin);
      return isLoggedIn;
    }
  };
};

export const THContainer = THContainerWrapper;
