// @ts-check
import memoizeOne from 'memoize-one';
import UrlUtils from '../UrlUtils';
import { endTime } from '../videoTimeMeta';
import { isOnDemand } from '../filterVideos';
import { VIDEO_STATUS } from '../../constants/videoStatus';

const { UPCOMING, CURRENT, FINISHED, ON_DEMAND } = VIDEO_STATUS;

/**
 * @typedef Item
 * @type {Object}
 * @property {string} sessionDescription
 * @property {string} sessionTitle
 * @property {string} lengthOfSession
 * @property {string} sessionDate
 * @property {string} sessionTime
 * @property {string} vidyardId
 * @property {boolean} isStatic
 * @property {boolean} featured
 * @property {string} slug
 * @property {Object[]} speakers
 * @property {Object[]} thModules
 * @property {Object[]} thTrailmixes
 * @property {Object[]} academyCourses
 * @property {string} datetime - ISO-formatted string of the sessionDate and sessionTime
 * @property {string} thumbnailUrl
 * @property {string} duration - Unix-formatted string of lengthOfSession (in milliseconds)
 * @property {string} startTime - Unix-formatted string of lengthOfSession (in milliseconds) that's incremented as a video is live and runs over its scheduled duration
 * @property {string} startTime - Unix formatted string of datetime
 */

/**
  * @typedef GroupedVideos
  * @type {Object}
  * @property {Item[]} current
  * @property {Item[]} finished
  * @property {Item[]} onDemand
  * @property {Item[]} upcoming

  */
const sortAscByStartTimeVideos = memoizeOne(videos => {
  return [...videos].sort((a, b) => {
    const aTime = new Date(a.datetime).getTime();
    const bTime = new Date(b.datetime).getTime();
    return aTime - bTime;
  });
});

const sortDescByEndTimeVideos = memoizeOne(videos =>
  [...videos].sort((a, b) => endTime(b) - endTime(a))
);

/**
 * @typedef videosMetadata
 * @type {Object}
 * @property {number} duration
 * @property {number} durationWithOvertime
 * @property {number} startTime
 */
/**
 * Return the upcoming items, videos whose start time is greater than now.
 * @param {Object} obj
 * @param {number} obj.time formatted as a UNIX timestamp
 * @param {Item[]} obj.videos
 * @param {videosMetadata} obj.videosMetadata time information we update about the video, related to how long it was live
 * @returns {Item[]} upcoming videos
 */
const upcomingItems = ({ time, videos, videosMetadata }) => {
  const sortedVideos = sortAscByStartTimeVideos(videos);
  return sortedVideos.filter(({ id }) => {
    const { startTime } = videosMetadata[id];
    return startTime > time;
  });
};

/**
 * Return the current item, video whose time is in range: startTime less than now and actual end ttime is greater than now.
 * End time is incremented when item is live past its scheduled duration.
 * @param {Object} obj
 * @param {number} obj.time formatted as a UNIX timestamp
 * @param {Item[]} obj.videos
 * @param {videosMetadata} obj.videosMetadata the time information we update about the video, related to how long it was live
 * @returns {Item[]} current videos
 */
const currentItems = ({ time, videos, videosMetadata }) => {
  const sortedVideos = sortAscByStartTimeVideos(videos);
  return sortedVideos.filter(({ id }) => {
    const { durationWithOvertime, startTime } = videosMetadata[id];
    const endTimeWithOvertime = startTime + durationWithOvertime;
    return startTime <= time && endTimeWithOvertime >= time;
  });
};

/**
 * @param {Object} obj
 * @param {number} obj.time formatted as a UNIX timestamp
 * @param {Item[]} obj.videos
 * @param {videosMetadata} obj.videosMetadata the time information we update about the video, related to how long it was live
 * @returns {Item[]} finished items that do not have a vidyardId
 */
const finishedItems = ({ time, videos, videosMetadata }) => {
  const sortedVideos = sortDescByEndTimeVideos(videos);
  const finished = sortedVideos.filter(({ id }) => {
    const { durationWithOvertime, startTime } = videosMetadata[id];
    const endTimeWithOvertime = startTime + durationWithOvertime;
    return endTimeWithOvertime < time;
  });
  return finished;
};

/**
 * @param {Object} obj
 * @param {number} obj.time formatted as a UNIX timestamp
 * @param {Item[]} obj.videos
 * @param {videosMetadata} obj.videosMetadata the time information we update about the video, related to how long it was live
 * @returns {Item[]} past items that have a vidyardId and property `isStatic: true` (comes from graphql node creation in build process)
 */
const onDemandItems = ({ time, videos }) => {
  const sortedVideos = sortDescByEndTimeVideos(videos);
  return sortedVideos.filter(video => isOnDemand({ video, time }));
};

/**
 * @param {GroupedVideos} groupedVideos
 * @returns {Item[]} flattened array of all video items
 */
const flattenedVideos = groupedVideos => {
  const unNestedArray = (acc, arr) => acc.concat(arr);
  return Object.values(groupedVideos).reduce(unNestedArray, []);
};

/**
 * @param {Object} videosData
 * @param {number} videosData.time
 * @param {Item[]} videosData.videos
 * @param {Object} videosData.videosMetadata
 * @returns {GroupedVideos} collections of videos, grouped by status
 */

const videosByGroup = videosData => ({
  current: currentItems(videosData),
  upcoming: upcomingItems(videosData),
  onDemand: onDemandItems(videosData),
  finished: finishedItems(videosData)
});

/**
 * @param {string} lookupId video slug that needs to be found in grouped videos
 * @param {Object} groupedVideos
 * @returns {{status: string, video: Object}} video with status
 */
const videoWithStatusById = (lookupId, groupedVideos) => {
  let status;
  let video;
  const groupToStatusMatcher = {
    current: CURRENT,
    upcoming: UPCOMING,
    onDemand: ON_DEMAND,
    finished: FINISHED
  };
  const videoAndStatus = group => {
    const videoGroup = groupedVideos[group];
    // eslint-disable-next-line no-unused-expressions
    return videoGroup?.find(vid => {
      const matchFound = UrlUtils.idFromSlug(vid.slug) === lookupId;
      if (!matchFound) return false;
      video = vid;
      status = groupToStatusMatcher[group];
      return matchFound;
    });
  };
  Object.keys(groupedVideos).find(videoAndStatus);
  return status && video ? { status, video } : undefined;
};

export {
  currentItems,
  finishedItems,
  flattenedVideos,
  onDemandItems,
  upcomingItems,
  videosByGroup,
  videoWithStatusById
};
