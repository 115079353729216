import axios from 'axios';
import errorNotifier from '../utils/errorNotifier';

const retryFactory = ({ retries }) => ({ err }) => {
  const statusCode = err?.response?.status;
  const retryCodes = [408, 429, 500, 502, 503, 504, 522, 524];
  if (retryCodes.includes(statusCode) && retries > 0) {
    // eslint-disable-next-line no-param-reassign
    retries -= 1;
    return true;
  }
  return false;
};

// backoff will double every time after the first the first call
const backoffIncreaser = ((power = -1) => backoff => {
  // eslint-disable-next-line no-param-reassign
  power += 1;
  return 2 ** power * backoff;
})();

const fetch = ({
  url,
  options,
  retries = 0,
  backoff = 1000,
  onSuccess,
  onFailure
}) => {
  const shouldRetry = retryFactory({ retries });
  if (!url)
    throw new Error(
      `Invalid arguments. Please pass Object with required 'url' property.`
    );
  const doFetch = () =>
    axios
      .get(url, options)
      .then(res => {
        if (onSuccess) onSuccess(res);
        return res;
      })
      .catch(err => {
        errorNotifier(err, { shouldRetry });
        if (shouldRetry({ err })) {
          // try the call again, doubling backoff each time
          setTimeout(doFetch, backoffIncreaser(backoff));
        } else if (onFailure) onFailure();
      });
  return doFetch();
};

export { fetch, backoffIncreaser, retryFactory };
