const config = {
  auth: {
    url: process.env.GATSBY_AUTH_URL,
    isEnabled: process.env.GATSBY_AUTH_ENABLED !== 'false'
  },
  bff: {
    url: process.env.GATSBY_BFF_URL
  },
  cdn: {
    assetPath: process.env.ASSET_DOMAIN_AND_PATH,
    isEnabled: Boolean(process.env.ASSET_DOMAIN_AND_PATH)
  },
  cors: {
    'trailhead-live-development': false,
    'trailhead-live-staging': false,
    'trailhead-live-perf': false,
    'trailhead-live-perf-prod': false,
    'trailhead-live-staging-public': false,
    'trailhead-live-production': false,
    default: false
  },
  drm: {
    url: process.env.GATSBY_DRM_URL,
    data_dir: process.env.DRM_DATA_DIR,
    proxy: {
      target: process.env.DRM_PROXY_TARGET,
      localPath: process.env.DRM_PROXY_LOCAL_PATH,
      targetPath: process.env.DRM_PROXY_TARGET_PATH
    }
  },
  heroku: {
    appName: process.env.HEROKU_APP_NAME,
    isReviewApp: Boolean(process.env.HEROKU_PARENT_APP_NAME)
  },
  videosPath: '/videos/',
  broadcast: {
    iframe: process.env.GATSBY_BROADCAST_IFRAME
  },
  calendar: {
    icalServer: process.env.GATSBY_TRAILHEAD_LIVE_CALENDAR
  },
  tdx: {
    startDate: process.env.GATSBY_TDX_START_TIME,
    endDate: process.env.GATSBY_TDX_END_TIME
  }
};

module.exports = config;
