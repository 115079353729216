export const UPDATE_GROUPED_VIDEOS = 'UPDATE_GROUPED_VIDEOS';
export const UPDATE_VIDEO_METADATA = 'UPDATE_VIDEO_METADATA';
export const UPDATE_TIME = 'UPDATE_TIME';
export const ADD_VIDEOS = 'ADD_VIDEOS';

/* Actor, Verb, Object */
export const USER_PLAYED_VIDEO = 'USER_PLAYED_VIDEO';
export const USER_CLICKED_ADD_TO_CALENDAR = 'USER_CLICKED_ADD_TO_CALENDAR';
export const USER_CLICKED_LOGIN_CTA = 'USER_CLICKED_LOGIN_CTA';
export const USER_CLICKED_SIGNUP_CTA = 'USER_CLICKED_SIGNUP_CTA';
