import { addMinutes, format, getTime, getYear } from 'date-fns';

export default class DatetimeUtils {
  static getLocalizedDate = dt => {
    return format(new Date(dt), 'MMM do');
  };

  static getLocalizedTime = dt => {
    const utc = new Date(dt);
    return format(utc, 'p');
  };

  static getLocalizedDateWithYear = dt =>
    format(new Date(dt), "MMM do',' yyyy 'at' p");

  static getLocalizedTimestamp = dt => {
    return getTime(new Date(dt));
  };

  static getFullYear = dt => {
    return getYear(new Date(dt));
  };

  static getTimestampNoMs = dt => {
    const dts = new Date(dt);
    const dtDateOnly = dts.valueOf() + dts.getTimezoneOffset() * 60 * 1000;
    return format(dtDateOnly, "yyyyMMdd'T'HHmm");
  };

  static addSessionLength = (dt, sessionLength) => {
    const dts = new Date(dt);
    const sessionDate = addMinutes(dts, sessionLength);
    return this.getTimestampNoMs(sessionDate);
  };
}
