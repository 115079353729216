import React, { useCallback, useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import VideosContext from '.';
import BroadcastingContext from '../BroadcastingContext';
import useInterval from '../../hooks/useInterval';
import {
  updateGroupedVideos,
  updateTime,
  updateVideoMetadata,
  addVideos
} from '../../store/actions';
import { initVideosState } from '../../store/reducers/reducerHelpers';
import videosReducer from '../../store/reducers/videosReducer';
import { fetch as fetchFn } from '../../api/fetch';
import config from '../../config';
import { VIDEO_STATUS_CHECK_INTERVAL } from '../../constants/videoStatus';
import {
  VIDEO_FETCH_BACKOFF,
  VIDEO_FETCH_RETRIES,
  VIDEO_POLL_INTERVAL
} from '../../constants/videoFetching';

export const updateDynamicVideosFn = ({
  url,
  retries,
  fetch = fetchFn,
  onSuccess,
  onFailure
}) => {
  /**
   * Fetch videos from endpoint, and retry n times if the request fails with
   * certain status codes. Dispatch action on success/failure.
   */
  fetch({
    url,
    retries,
    onSuccess,
    onFailure
  });
};

export const checkVideosStatus = ({
  isLive,
  date,
  time,
  videosMetadata,
  groupedVideos,
  dispatch
}) => {
  /* We are only interested in updating metadata for the first current video */
  const [firstCurrent] = groupedVideos.current;

  if (isLive && Boolean(firstCurrent)) {
    const currentMeta = videosMetadata[firstCurrent?.id];
    const { startTime, duration } = currentMeta;
    const isOverDuration = startTime + duration < time;
    /**
     * Video is live and running over scheduled time, dispatch action to update
     * its metadata, e.g increases `durationWithOvertime`
     */
    if (isOverDuration) {
      dispatch(
        updateVideoMetadata({
          video: firstCurrent
        })
      );
    }
  } else {
    dispatch(updateGroupedVideos());
  }
  dispatch(updateTime({ time: date.now() }));
};
/**
 * Provides consumers with an object containing scheduled videos grouped by status
 *
 * Example:
 *
 *    {
 *      "current": [],
 *      "finished": [],
 *      "upcoming": []
 *    }
 *
 * Videos are grouped based on the current time. What's considered a
 * current video now might not be current in an hour. Video status is checked
 * via a timer, `useInterval()`, that's called regularly with a callback,
 * `checkVideoStatus()`, at a defined interval (`delay`).
 *
 * `checkVideosStatus()` will dispatch actions to update `videosMetadata` and
 * `groupedVideos`, based on conditions such as whether there's a current video,
 * whether it's live, whether it's running over it's scheduled duration, etc.
 *
 * NOTE: date and updateDynamicVideos are added as a dependencies here for ease of testing
 *
 */
const VideosProvider = ({
  children,
  date = Date,
  updateDynamicVideos = updateDynamicVideosFn
}) => {
  const { broadcasting: isLive } = useContext(BroadcastingContext);
  /**
   * Example
   *
   *    "videosMetadata": {
   *      "id": {
   *        "duration": 1000,
   *        "durationWithOvertime: 1000,
   *        "startTime": 1588225845895
   *      }
   *    }
   *
   * `durationWithOvertime`
   *
   *  - Increased when the first current video is live and is running over its duration.
   *
   *  - Used to calculate when a video was finished.
   *
   *    For instance, if a video runs over its scheduled duration, it would be
   *    considered "finished" beginning from when it actually ended.
   *
   */
  const [{ videosMetadata, groupedVideos, time, ready }, dispatch] = useReducer(
    videosReducer,
    {
      time: date.now(),
      videos: [],
      ready: false
    },
    initVideosState
  );

  const onSuccess = useCallback(
    ({ data: { items: fetchedVideos } }) =>
      dispatch(
        addVideos({
          fetchedVideos,
          ready: true
        })
      ),
    [dispatch]
  );
  const onFailure = useCallback(
    () =>
      dispatch(
        addVideos({
          fetchedVideos: [],
          ready: true
        })
      ),
    [dispatch]
  );
  useEffect(
    () =>
      updateDynamicVideos({
        onSuccess,
        onFailure,
        url: config.bff.url,
        retries: VIDEO_FETCH_RETRIES,
        dispatch
      }),
    [dispatch, updateDynamicVideos, onSuccess, onFailure]
  );
  /** Timer that controls the grouping of all the videos, which are grouped based on their start time and duration */
  useInterval(
    () =>
      checkVideosStatus({
        date,
        isLive,
        time,
        videosMetadata,
        groupedVideos,
        dispatch
      }),
    VIDEO_STATUS_CHECK_INTERVAL
  );
  /** Polling to update the re-fetch the videos at a regular interval, unless a video is running over time (we don't want to reset video state in that case) */
  useInterval(
    () =>
      updateDynamicVideos({
        onSuccess,
        onFailure,
        url: config.bff.url,
        retries: VIDEO_FETCH_RETRIES,
        backoff: VIDEO_FETCH_BACKOFF,
        dispatch
      }),
    VIDEO_POLL_INTERVAL
  );
  /** Memoizes the context value and refreshes it when groupedVideos or the ready state changes. */
  const value = React.useMemo(() => ({ ...groupedVideos, ready }), [
    groupedVideos,
    ready
  ]);

  return (
    <VideosContext.Provider value={value}>{children}</VideosContext.Provider>
  );
};

VideosProvider.propTypes = {
  children: PropTypes.node.isRequired,
  videosValue: PropTypes.shape({}).isRequired
};

VideosProvider.propTypes = {
  children: PropTypes.node.isRequired,
  updateDynamicVideos: PropTypes.func,
  date: PropTypes.shape({})
};

export default VideosProvider;
