const VIDEO_STATUS = {
  ON_DEMAND: 'ON_DEMAND',
  UPCOMING: 'UPCOMING',
  FINISHED: 'FINISHED',
  CURRENT: 'CURRENT',
  DYNAMIC: 'DYNAMIC'
};

const VIDEO_STATUS_CHECK_INTERVAL = 1000;

export { VIDEO_STATUS, VIDEO_STATUS_CHECK_INTERVAL };
