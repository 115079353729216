// @ts-check
import isValidItem from '../ValidItemUtils';
import isEmpty from '../isEmpty';
import { endTime } from '../videoTimeMeta';
import { VIDEO_STATUS } from '../../constants/videoStatus';

/**
 * @param {Object} obj
 * @param {Object} obj.video
 * @param {number} obj.time formatted as a UNIX timestamp
 */
const isOnDemand = ({ video, time }) =>
  endTime(video) < time && video.vidyardId && video.isStatic;

const typeOfVideo = ({ video, time }) => {
  if (isEmpty(video)) return null;
  if (isOnDemand({ video, time })) return VIDEO_STATUS.ON_DEMAND;
  // Current, upcoming, or finished
  return VIDEO_STATUS.DYNAMIC;
};

const filterVideos = ({ videos, type, time = Date.now() }) => {
  if (!VIDEO_STATUS[type]) {
    return videos;
  }
  const filtered = videos.filter(video => {
    const videoType = typeOfVideo({ video, time });
    return isValidItem(video) && videoType === type;
  });
  return filtered;
};

export { filterVideos, typeOfVideo, isOnDemand };
