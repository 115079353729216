import React from 'react';
import sldsUtilityIcon from '../assets/vendor/slds/icons/utility-sprite/svg/symbols.svg';

const BrowserNotice = () => {
  return (
    <div className="tlv-browser-notice" style={{ display: 'none' }}>
      <div
        className="slds-notify slds-notify_alert slds-theme_alert-texture slds-theme_warning tds-notify_alert"
        role="alert"
      >
        <span className="slds-assistive-text">warning</span>
        <span className="slds-m-right_x-small">
          <svg aria-hidden="true" className="slds-icon slds-icon_x-small">
            <use xlinkHref={`${sldsUtilityIcon}#warning`} />
          </svg>
        </span>
        <h2>
          Your web browser (Internet Explorer) is not supported on Trailhead
          Live. Please upgrade to Microsoft Edge.
        </h2>
      </div>
    </div>
  );
};
export default BrowserNotice;
