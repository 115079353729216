import { flattenedVideos, videosByGroup } from '../../utils/videoGrouping';

const getVideosMetadata = videos => {
  const videoMetadata = video => ({
    duration: parseInt(video.lengthOfSession, 10) * 60 * 1000,
    durationWithOvertime: parseInt(video.lengthOfSession, 10) * 60 * 1000,
    startTime: new Date(video.datetime).getTime()
  });
  return videos.reduce((acc, video) => {
    acc[video.id] = videoMetadata(video);
    return acc;
  }, {});
};

const initVideosState = ({ time, videos, ready = false }) => {
  const allVideosMetadata = getVideosMetadata(videos);
  const { onDemand, ...groups } = videosByGroup({
    time,
    videos,
    videosMetadata: allVideosMetadata
  });
  const videosMetadata = Object.keys(allVideosMetadata).reduce(
    (acc, metaId) => {
      const inGroupedVideos = flattenedVideos(groups).find(
        ({ id }) => metaId === id
      );
      // remove id from videosMetadata if it's not in groupedVideos (can
      // happen when there's more than one recntly finished item and it gets
      // left out)
      if (!inGroupedVideos) return acc;
      return Object.assign(acc, { [metaId]: allVideosMetadata[metaId] });
    },
    {}
  );

  return {
    videosMetadata,
    groupedVideos: groups,
    time,
    ready
  };
};

export { initVideosState, getVideosMetadata };
