import {
  ADD_VIDEOS,
  UPDATE_GROUPED_VIDEOS,
  UPDATE_VIDEO_METADATA,
  UPDATE_TIME,
  USER_PLAYED_VIDEO,
  USER_CLICKED_ADD_TO_CALENDAR,
  USER_CLICKED_LOGIN_CTA,
  USER_CLICKED_SIGNUP_CTA
} from './actionTypes';

export const updateGroupedVideos = payload => {
  return {
    type: UPDATE_GROUPED_VIDEOS,
    payload
  };
};

export const updateTime = payload => ({
  type: UPDATE_TIME,
  payload
});

// Given a video, find its videosMetadata and set/increase its `durationWithOvertime`
export const updateVideoMetadata = payload => {
  return {
    type: UPDATE_VIDEO_METADATA,
    payload
  };
};

export const addVideos = payload => ({
  type: ADD_VIDEOS,
  payload
});

export const userPlayedVideo = payload => ({
  type: USER_PLAYED_VIDEO,
  payload
});

export const userClickedAddToCalendar = payload => ({
  type: USER_CLICKED_ADD_TO_CALENDAR,
  payload
});

export const userClickedLoginCta = payload => ({
  type: USER_CLICKED_LOGIN_CTA,
  payload
});

export const userClickedSignupCta = payload => ({
  type: USER_CLICKED_SIGNUP_CTA,
  payload
});
