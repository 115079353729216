import React, { useState, createRef, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import userActivityReducer from '../../store/reducers/userActivityReducer';
import { userPlayedVideo } from '../../store/actions';
import { UserPlayedVideoEvent } from '../../events/userPlayedVideo';
import isEmpty from '../../utils/isEmpty';
import BroadcastingContext from '.';
import Iframe from './Iframe';

const BroadcastIframe = React.forwardRef(
  ({ isHandlerAdded, iframeHidden }, ref) => (
    <Iframe
      broadcastFrameRef={ref}
      isHandlerAdded={isHandlerAdded}
      iframeHidden={iframeHidden}
    />
  )
);

const BroadcastingProvider = ({ children }) => {
  const initialState = {
    playedVideo: false
  };
  const [live, setLive] = useState(null);
  const [, dispatch] = useReducer(userActivityReducer, initialState);
  const [videoInPlayer, setVideoInPlayer] = useState(null);
  const [playing, setPlaying] = useState();
  const [iframeHidden, setIframeHidden] = useState(true);
  const [isHandlerAdded, setIsHandlerAdded] = useState(false);
  const broadcastFrameRef = createRef();

  useEffect(() => {
    if (playing && !isEmpty(videoInPlayer)) {
      const { role, level, products, sessionTitle, speakers } = videoInPlayer;
      const payload = UserPlayedVideoEvent({
        category: 'Live',
        level,
        products,
        role,
        sessionTitle,
        speakers
      });
      dispatch(userPlayedVideo(payload));
    }
  }, [playing, videoInPlayer]);

  const onMessage = e => {
    if (!e.origin?.toLowerCase().includes('video.ibm')) return;
    try {
      const { event } = JSON.parse(e.data);
      const parsedEvent = rawEvent =>
        !isEmpty(rawEvent) ? Object.entries(rawEvent)[0] : [];
      const [eventName, eventData] = parsedEvent(event);

      const handlers = {
        live: isLive => setLive(isLive),
        playing: isPlaying => setPlaying(isPlaying)
      };
      if (handlers?.[eventName]) {
        handlers[eventName](eventData);
      }
    } catch (err) {
      // TODO: add better error handling here, or this try/catch may not be needed
      // console.error(err);
    }
  };

  const windowElement = typeof window !== 'undefined' ? window : undefined;
  useEffect(() => {
    if (windowElement) {
      windowElement.addEventListener('message', onMessage, true);
      // console.log('message listener added');
      // Iframe waits to set src until handler is added, because ustream
      // otherwise emits events too early, before we're listening
      setIsHandlerAdded(true);
    }
    return () => {
      if (windowElement) {
        // console.log('message listener removed');
        windowElement.removeEventListener('message', onMessage, true);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const value = {
    broadcasting: live,
    setIframeHidden,
    setVideoInPlayer,
    iframe: (
      <BroadcastIframe
        broadcastFrameRef={broadcastFrameRef}
        isHandlerAdded={isHandlerAdded}
        iframeHidden={iframeHidden}
      />
    )
  };
  return (
    <BroadcastingContext.Provider ref={broadcastFrameRef} value={value}>
      {children}
    </BroadcastingContext.Provider>
  );
};

export default BroadcastingProvider;

BroadcastIframe.propTypes = {
  isHandlerAdded: PropTypes.bool.isRequired,
  iframeHidden: PropTypes.bool.isRequired
};
BroadcastingProvider.propTypes = {
  children: PropTypes.node.isRequired
};
