import qs from 'query-string';
import DatetimeUtils from './DatetimeUtils';

export default class UrlUtils {
  static capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  static getQueryString = params => {
    let key = false;
    const res = {};
    let itm = null;

    // get the query string without the ?
    const queryString = window?.location.search.substring(1);
    // check for the key as an argument
    if (params.length > 0 && params[0].length > 1) [key] = params;
    // make a regex pattern to grab key/value
    const pattern = /([^&=]+)=([^&]*)/g;
    // loop the items in the query string, either
    // find a match to the argument, or build an object
    // with key/value pairs
    // eslint-disable-next-line no-cond-assign
    while ((itm = pattern.exec(queryString))) {
      if (key !== false && decodeURIComponent(itm[1]) === key)
        return decodeURIComponent(itm[2]);
      if (key === false)
        res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
    }

    return key === false ? res : null;
  };

  static getParameterByName = name => {
    // name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window?.location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  static getAllParameters = location => {
    const search = location.search.replace('?', '');
    const pairs = [];
    if (search !== '') {
      const searchArr = search.split('&');
      searchArr.forEach(pair => {
        const pairArr = pair.split('=');
        pairs.push({ key: pairArr[0], value: pairArr[1] });
      });
    }

    return pairs;
  };

  static preserveQueryString(url, window = global) {
    if (typeof window !== 'undefined') {
      // When users access Trailhead Live from Trailhead GO, a query parameter of 'nc=1' is supplied.
      // This enables the content router to know whether or not to add the mobile header & footer in the client
      const existingParams = qs.parse(window?.location?.search);
      const mobileParamIsPresent = parseInt(existingParams.nc, 10) === 1;
      if (mobileParamIsPresent) {
        return qs.stringifyUrl({ url, query: { nc: 1 } });
      }
    }
    return url;
  }

  static getGoogleCalendarUrl(
    sessionDescription,
    sessionTitle,
    speakerName,
    lengthOfSession,
    datetime,
    gatedLive,
    slug
  ) {
    const startDate = DatetimeUtils.getTimestampNoMs(datetime);
    const endDate = DatetimeUtils.addSessionLength(datetime, lengthOfSession);
    const calendarMeta = { sessionTitle, sessionDescription, speakerName };
    const loginRequired = gatedLive
      ? "%0A%0A<b>You'll need to log in to watch this broadcast</b>"
      : '';
    const encodedMeta = Object.keys(calendarMeta).reduce((acc, key) => {
      return { ...acc, [key]: encodeURIComponent(calendarMeta[key]) };
    }, {});

    return `https://www.google.com/calendar/event?action=TEMPLATE&dates=${startDate}00Z%2F${endDate}00Z&text=${encodedMeta.sessionTitle} with ${encodedMeta.speakerName}&location=https://trailhead.salesforce.com/live/broadcasts/${slug}/&details=${encodedMeta.sessionDescription}%0A%0AJoin us at%0Ahttps://trailhead.salesforce.com/live/broadcasts/${slug}/${loginRequired}`;
  }

  static pageName(slug) {
    if (!slug) return '';
    const livePrefix = new RegExp('(^/live[/]?)|(^/)'); // Matches /live/|broadcasts|videos, /live/, /live, or / at beginning of string
    const slugNoPrefix = slug.replace(livePrefix, ''); // Removes prefix: => broadcasts/* videos/* schedule/* or '' (homepage)
    const [pageName] = slugNoPrefix.match('^[^/]+') || []; // get the string before the first slash
    return pageName || ''; // returns string, e.g. 'broadcasts' 'videos' 'schedule' ''
  }

  static idFromSlug(slug = '') {
    const slugPrefix = new RegExp('(.*?)(/videos/)|(.*?)(/broadcasts/)'); // Matches both /videos|broadcasts (dev) and /live/videos|broadcasts (prod)
    const slugWithoutPrefix = slug.replace(slugPrefix, ''); // Removes prefix in slug: /videos|broadcasts/id/title or /live/videos|broadcasts/id/title => /id/title
    const regexMatchAfterSlash = new RegExp('/.*$'); // Matches everything after the first "/"

    const idOnly = slugWithoutPrefix.replace(regexMatchAfterSlash, ''); // Gets rid of title

    return idOnly;
  }
}
