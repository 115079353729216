import errorNotifier from '../utils/errorNotifier';

export const UserPlayedVideoEvent = ({
  sessionTitle,
  speakers,
  role = '',
  level = '',
  products = '',
  category
}) => {
  const gtmParams = new Map();
  gtmParams.set('Live', {
    eventCategory: 'Livestream',
    eventAction: 'New Livestream'
  });
  gtmParams.set('On-Demand', {
    eventCategory: category,
    eventAction: 'Play Video'
  });
  return {
    asUserActivity: () => {
      return {
        category,
        level,
        products,
        role,
        speakers,
        sessionTitle
      };
    },
    asGTM: () => {
      if (!gtmParams.has(category)) {
        const msg = `Invalid category '${category}' supplied to UserPlayedVideoEvent. Expected one of: ${[
          ...gtmParams.keys()
        ]}`;
        errorNotifier(msg);
        // eslint-disable-next-line no-console
        return console.debug(msg);
      }
      const { eventAction, eventCategory } = gtmParams.get(category);
      return {
        event: 'eventTracker',
        eventCategory,
        eventAction,
        eventLabel: sessionTitle,
        eventValue: 0,
        nonInteraction: 0,
        cd3: sessionTitle,
        cd4: role,
        cd5: level,
        cd6: products
      };
    }
  };
};
