// Checks that the following fields exist on an item before creating a page for it and/or showing it in the UI
const isValidItem = item =>
  item.sessionTitle !== '' &&
  item.sessionTime !== '' &&
  item.sessionDate !== '' &&
  Array.isArray(item.speakers) &&
  item.speakers.length > 0 &&
  item.speakers.speakerName !== '' &&
  item.speakers.speakerTitle !== '' &&
  item.speakers.imageUrl !== '';

// using CommonJS export because function is used in gatsby-node.js file (in addition to HomeContent.js) and runs during node build
module.exports = isValidItem;
