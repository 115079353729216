import { fetch } from '../api/fetch';
import config from '../config';
import { LOGGED_IN } from '../constants/authentication';
import isThGoUser from './isThGoUser';
import errorNotifier from './errorNotifier';

const getAuthStatus = () =>
  fetch({ url: config.auth.url })
    .then(({ data }) => data.user === LOGGED_IN)
    .catch(err => {
      errorNotifier(err, {
        message: `Could not retrieve user auth state`
      });
    });

// Todo: the check in this fn for a TH Go user could be made more robust (tbc in another story)
const isAuthenticated = async ({
  window = global,
  isLoggedIn = getAuthStatus
} = {}) => {
  if (isThGoUser({ window })) return true;
  return isLoggedIn();
};

export default isAuthenticated;
