import { filterVideos } from '../../utils/filterVideos';
import { initVideosState } from './reducerHelpers';
import { flattenedVideos, videosByGroup } from '../../utils/videoGrouping';
import { VIDEO_STATUS } from '../../constants/videoStatus';
import isEmpty from '../../utils/isEmpty';

const isOverTime = ({ metadata }) => {
  return metadata?.duration < metadata?.durationWithOvertime;
};
const firstCurrentIdAndMetadata = ({ current, videosMetadata }) => {
  const currentId = current[0]?.id;
  return {
    currentId,
    currentMeta: videosMetadata?.[currentId]
  };
};

const reducers = {
  ADD_VIDEOS: (state, action) => {
    const {
      time,
      videosMetadata,
      groupedVideos: { current }
    } = state;
    const { fetchedVideos, ready } = action.payload;

    const { currentId, currentMeta } = firstCurrentIdAndMetadata({
      current,
      videosMetadata
    });

    const isCurrentVideoRunningOver = isOverTime({ metadata: currentMeta });

    // TODO: We should remove this if we can add this data to the videos in the BFF
    const formattedVideos = fetchedVideos.reduce((acc, vid) => {
      const video = {
        ...vid,
        id: vid.slug,
        datetime: `${vid.sessionDate}T${vid.sessionTime}`
      };

      acc.push(video);
      return acc;
    }, []);

    const dynamicVideos = filterVideos({
      videos: formattedVideos,
      type: VIDEO_STATUS.DYNAMIC,
      time
    });
    const result = initVideosState({
      time,
      videos: [...dynamicVideos],
      ready
    });

    const persistCurrentVideoWithResult = {
      ...result,
      ...{
        videosMetadata: {
          ...result.videosMetadata,
          [currentId]: currentMeta
        },
        groupedVideos: {
          ...result.groupedVideos,
          current
        }
      }
    };
    return isCurrentVideoRunningOver ? persistCurrentVideoWithResult : result;
  },
  UPDATE_GROUPED_VIDEOS: state => {
    const { time, groupedVideos, videosMetadata } = state;
    const nextGroupedVideos = videosByGroup({
      time,
      videos: flattenedVideos(groupedVideos),
      videosMetadata
    });
    /* This action is called a lot, so we check if the state really needs to be updated by
    matching the previous groupedVideos id's to groupedVideos id's on the payload */
    // For every video group (current, upcoming, finished)...
    const skipUpdate = Object.keys(groupedVideos).every(groupName => {
      // create a Set from the previous group's id's and check that
      // all the new group's id's are in the Set.
      const prevIds = new Set();
      const group = groupedVideos[groupName];
      const nextGroup = nextGroupedVideos[groupName];
      group.forEach(({ id }) => prevIds.add(id));
      const areVideosInGroupUnchanged = nextVideos => {
        if (isEmpty(nextVideos)) {
          return isEmpty(prevIds);
        }
        return nextVideos.every(({ id }) => prevIds.has(id));
      };
      return areVideosInGroupUnchanged(nextGroup);
    });

    // if id's didn't change, skip update and return previous state.
    return skipUpdate ? state : { ...state, groupedVideos: nextGroupedVideos };
  },
  UPDATE_VIDEO_METADATA: (state, action) => {
    const { time, videosMetadata } = state;
    const { video } = action.payload;
    const currentMeta = videosMetadata[video.id];
    const { startTime } = currentMeta;
    const updated = {
      ...videosMetadata,
      [video.id]: {
        ...currentMeta,
        durationWithOvertime: time - startTime
      }
    };
    return { ...state, videosMetadata: updated };
  },
  UPDATE_TIME: (state, action) => {
    const { time } = action.payload;
    return { ...state, time };
  }
};

const videosReducer = (state, action) => {
  return reducers[action.type](state, action);
};

export default videosReducer;
