// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-broadcasts-id-title-index-js": () => import("./../../../src/pages/broadcasts/id/title/index.js" /* webpackChunkName: "component---src-pages-broadcasts-id-title-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schedule-index-js": () => import("./../../../src/pages/schedule/index.js" /* webpackChunkName: "component---src-pages-schedule-index-js" */),
  "component---src-pages-test-index-js": () => import("./../../../src/pages/test/index.js" /* webpackChunkName: "component---src-pages-test-index-js" */),
  "component---src-pages-videos-index-js": () => import("./../../../src/pages/videos/index.js" /* webpackChunkName: "component---src-pages-videos-index-js" */),
  "component---src-templates-video-template-js": () => import("./../../../src/templates/VideoTemplate.js" /* webpackChunkName: "component---src-templates-video-template-js" */)
}

