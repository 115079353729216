import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config';

const Iframe = ({ broadcastFrameRef, isHandlerAdded, iframeHidden }) => (
  <iframe
    ref={broadcastFrameRef}
    id="broadcastFrame"
    title="Trailhead Live Stream"
    src={isHandlerAdded ? config.broadcast.iframe : ''}
    width={iframeHidden ? 0 : '100%'}
    height={iframeHidden ? 0 : '100%'}
    frameBorder="0"
    allowFullScreen
    webkitallowfullscreen="true"
    style={{ display: 'block' }}
    data-chromatic="ignore"
  />
);

Iframe.propTypes = {
  broadcastFrameRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType })
  ]),
  isHandlerAdded: PropTypes.bool.isRequired,
  iframeHidden: PropTypes.bool.isRequired
};

export default Iframe;
