import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import AuthProvider from '../contexts/AuthContext/AuthProvider';
import BroadcastingProvider from '../contexts/BroadcastingContext/BroadcastingProvider';
import VideosProvider from '../contexts/VideosContext/VideosProvider';
import BrowserNotice from '../components/BrowserNotice';
// import I18n from '../utils/I18n';
import '../assets/stylesheets/index.scss';

// TODO: Discuss plans around localization for V2 or code related to I18n
const Layout = ({ children }) => {
  // const lang = 'en';
  // const i18n = new I18n({ lang });

  return (
    <>
      {/* prettier-ignore */}
      <Helmet>
        {/**
         * `TDS` is served from the static folder and it is not included in the compiled CSS because of performance reasons.
         * This is only needed for local development and review apps. In production, TDS styles are imported by the Trailhead Header.
         * In the future we may optimize differently.
         */}
        <link rel="stylesheet" media="all" href={withPrefix('assets/tds/styles/index-min.gz.css')} />
      </Helmet>
      <main id="tlv-site" className="tlv-site">
        <BroadcastingProvider>
          <VideosProvider>
            <AuthProvider>{children}</AuthProvider>
          </VideosProvider>
        </BroadcastingProvider>
        <BrowserNotice />
      </main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
