/**
 * @param {Object} video
 *  * @param {string|number} video.datetime session start time, as an ISO string. Will also accept epoch number.
 * @returns {number} start time in epoch milliseconds
 */
const startTime = ({ datetime }) => new Date(datetime).getTime();
/**
 * @param {Object} video
 *  * @param {string|number} video.lengthOfSession session length, in minutes
 * @returns {number} duration in milliseconds
 */
const duration = ({ lengthOfSession }) =>
  parseInt(lengthOfSession, 10) * 60 * 1000;

/**
 * @param {Object} video
 *  * @param {string|number} video.datetime session start time, as an ISO string. Will also accept epoch number.
 *  * @param {string|number} video.lengthOfSession session length, in minutes
 * @returns {number} end time in epoch milliseconds
 */
const endTime = video => startTime(video) + duration(video);

export { startTime, duration, endTime };
