// This is taken and adapted from the official Gatsby Google Tag Manager Plugin.
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-tagmanager/src/gatsby-browser.js
// We had to disable the plugin to prevent duplicated GTM tags on production.
// So now we need to trigger an event for GTM to properly track and gather dataLayer updates.
exports.onRouteUpdate = () => {
  if (typeof window !== 'undefined') {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      window.dataLayer = window.dataLayer || [];
      // prettier-ignore
      window.dataLayer.push({ 'event': `gatsby-route-update` });
    }, 50);
  }
};

const React = require('react');
const Layout = require('./src/layouts/Layout').default;
// eslint-disable-next-line react/prop-types
exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Layout {...props}>{element}</Layout>;
};
