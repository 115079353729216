import { USER_ACTIVITY } from '../constants/tracking';
import { THContainer } from '../gateways/trailhead';

export const UserActivity = {
  dispatch: (eventName, payload) => {
    const activityEvent = new CustomEvent(USER_ACTIVITY.EVENT_LISTENER, {
      bubbles: true,
      composed: true,
      detail: {
        eventName,
        payload
      }
    });
    THContainer().dispatchEvent(activityEvent);
  }
};
